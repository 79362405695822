var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { SELECTED_SAVED_FILTER_SET_ID_KEY, SAVED_FILTER_SETS_KEY } from '@amzn/austin-core';
var PREFIX = 'austin-incidents';
var LEGACY_SAVED_FILTERS_LOCAL_STORAGE_KEY = "".concat(PREFIX, ".custom-filter-views");
var LEGACY_SELECTED_FILTER_SET_LOCAL_STORAGE_KEY = "".concat(PREFIX, ".current-selected-filter");
var NEW_SAVED_FILTERS_LOCAL_STORAGE_KEY = "".concat(PREFIX, ".").concat(SAVED_FILTER_SETS_KEY);
var NEW_SELECTED_FILTER_SET_LOCAL_STORAGE_KEY = "".concat(PREFIX, ".").concat(SELECTED_SAVED_FILTER_SET_ID_KEY);
export var migrateSavedFilterSets = function () {
    try {
        var selectedFilterSet = JSON.parse(window.localStorage.getItem(LEGACY_SELECTED_FILTER_SET_LOCAL_STORAGE_KEY) || '{}');
        if (selectedFilterSet === null || selectedFilterSet === void 0 ? void 0 : selectedFilterSet.filterSetId) {
            window.localStorage.setItem(NEW_SELECTED_FILTER_SET_LOCAL_STORAGE_KEY, JSON.stringify(selectedFilterSet));
        }
        var savedFilters = JSON.parse(window.localStorage.getItem(LEGACY_SAVED_FILTERS_LOCAL_STORAGE_KEY) || '[]');
        if (savedFilters.length > 0) {
            var newSavedFilters = savedFilters.map(function (_a) {
                var tablePreferences = _a.tablePreferences, filter = __rest(_a, ["tablePreferences"]);
                return (__assign(__assign({}, filter), { preferences: tablePreferences }));
            });
            window.localStorage.setItem(NEW_SAVED_FILTERS_LOCAL_STORAGE_KEY, JSON.stringify(newSavedFilters));
        }
    }
    catch (e) {
        console.warn('Unable to migrate saved filters over. Error: ', e);
    }
};
export var shouldMigrateToNewFilters = function () {
    try {
        var hasNoNewFilters = window.localStorage.getItem(NEW_SAVED_FILTERS_LOCAL_STORAGE_KEY) === null;
        var existingSavedFilters = JSON.parse(window.localStorage.getItem(LEGACY_SAVED_FILTERS_LOCAL_STORAGE_KEY) || '[]');
        return hasNoNewFilters && existingSavedFilters.length > 0;
    }
    catch (e) {
        return false;
    }
};
